@charset "UTF-8";
/*
 * Ryan O'Dowd
 * 2020-01-27
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
/* @TODO: this should be done with styles, but i'm not sure how */

.selected fieldset {
  border-color: #225691 !important;
  border-width: 2px !important;
}
.selected fieldset legend {
  color: #225691 !important;
}
.selected input, .selected textarea, .selected label {
  color: #225691 !important;
}

.sameValue fieldset {
  border-color: #f5cc00 !important;
  border-width: 2px !important;
}
.sameValue fieldset legend {
  color: #f5cc00 !important;
}
.sameValue input, .sameValue textarea, .sameValue label {
  color: #f5cc00 !important;
}

fieldset:not(.sameValue) {
  cursor: pointer !important;
}
legend:not(.sameValue) {
  cursor: pointer !important;
}
input:not(.sameValue), textarea:not(.sameValue), label:not(.sameValue) {
  cursor: pointer !important;
}

.MuiFormControl-root.MuiTextField-root[color="secondary"] input,
.MuiFormControl-root.MuiTextField-root[color="secondary"] textarea {
  cursor: pointer !important;
}
