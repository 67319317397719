@charset "UTF-8";

/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
/* @NOTE: use this file minimally...only for global and/or non-component styling */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 100;
  line-height: 1rem;
}

body h1,
body h2,
body h3 {
  color: #0b5394;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: center;
}

body h4,
body h5,
body h6 {
  color: #0b5394;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;
}

body h1 {
  font-weight: 800;
  font-size: 2.6rem;
  line-height: 2.6rem;
}

body a {
  color: #0b5394;
}

body .expansion-panels-wrapper p {
  margin-top: 0;
}

.amplify-button--primary {
  background: #0b5394;
}

.css-1tktgsa-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-width: fit-content
}